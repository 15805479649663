import { setItem, removeItem } from '@utils'

function clearLocalStorage() {
    try{
        removeItem('USERTYPE')
        removeItem('TOKENACFAZ')
        removeItem('SESSION')
        removeItem('osUser')
        removeItem('mac')
        removeItem('IP')
        removeItem('IDUSUARIO')
        removeItem('MAQUINA')
        removeItem('USUARIO')
    }catch(e) {  }
}
  
export function listener(event) {
    clearLocalStorage()
    const { data } = event
    setItem('USERTYPE', data.userType)
    setItem('TOKENACFAZ', data.token)
    setItem('SESSION', data.session)
    setItem('osUser', data.osUser)
    setItem('mac', data.mac)
    setItem('IP', data.ip)
    setItem('IDUSUARIO', data.idusuario)
    setItem('MAQUINA', data.maquina);
    setItem('USUARIO', data.usuario)
}