import { api } from '../api'
const defaultRouteName = '/acfaz/videoconferencia/'

export const entrarVideoconferenciaAgr = (data) => api.post(
    `${defaultRouteName}sessao/agr`,
    data
)

export const entrarVideoconferenciaCliente = (data) => api.post(
    `${defaultRouteName}sessao/cliente`,
    data
)

export const iniciarGravacaoVideoconferencia = (data) => api.post(
    `${defaultRouteName}gravacao/iniciar`,
    data
)


export const finalizarGravacaoVideoconferencia = (data) => api.post(
    `${defaultRouteName}gravacao/finalizar`,
    data
)
