import { api } from '../api'
const defaultRouteName = '/acfaz/otp/'
const contexto = 'VIDEOCONFERENCIA'

export const enviarCodigoOtp = (idSolicitacao) => api.post(
    `${defaultRouteName}${contexto}/${idSolicitacao}/enviar`
)

export const validarCodigoOtp = (idSolicitacao, otp) => api.post(
    `${defaultRouteName}${contexto}/${idSolicitacao}/validar`, null, { params: { otp } }
)
