import React, { useEffect, useState } from 'react'

export const useSearchParameters = () => {
    const [searchParameters, setSearchParameters] = useState({});
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        setSearchParameters(params)
    }, []);

    return searchParameters;
}