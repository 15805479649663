import * as Sentry from '@sentry/react'

class ApiError extends Error {
  constructor(response) {
    super()
    this.message = response?.data?.message
    this.name = `API Error (${response?.status}) [${response?.data?.message?.replace(/[0-9]/g, '').replace(/-/g, '')}]`
    this.stack = new Error().stack
  }
}

export const intercept = async (api) => {
  api.interceptors.response.use(null, (error) => {
    if (error?.response?.status >= 400) {
      Sentry.captureException(new ApiError(error.response), {
        tags: {
          route: error.response.config.url,
          method: error.response.config.method
        },
        extra: {
          payload: error.response.config.data
        }
      })
    }
    return Promise.reject(error)
  })
}
