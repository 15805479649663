import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ENV from "@environments";
import { version } from "../../package.json";
import { intercept } from "./interceptor.log";

const executaSeAtivado = (action) => ENV.SENTRY.habilitado && action();

const ativar = () =>
  executaSeAtivado(() =>
    Sentry.init({
      dsn: "https://19f6430e24634ed08464ef6b3eb502d2@sentry.acertweb.com.br/4",
      integrations: [new Integrations.BrowserTracing()],
      environment: ENV.SENTRY.ambiente,
      release: version,
      tracesSampleRate: 0.1,
    })
  );

const iniciarSessao = (id, username) =>
  executaSeAtivado(() =>
    Sentry.configureScope(
      (scope) =>
        !scope?._user?.id &&
        Sentry.setUser({
          id,
          username
        })
    )
  );

const fecharSessao = () => executaSeAtivado(() => Sentry.configureScope((scope) => scope.setUser(null)));

const setInterceptor = (api) => executaSeAtivado(() => intercept(api));

const enviar = (error) => executaSeAtivado(() => Sentry.captureException(error));

export const Logger = {
  ativar,
  enviar,
  intercept: setInterceptor,
  sessao: {
    iniciar: iniciarSessao,
    fechar: fecharSessao,
  },
};
