import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import AppContainer from './containers/appContainer'
import { Logger } from '@logging';
import { listener } from '@utils'

Logger.ativar()

window.addEventListener('message', (event) => { 
  listener(event)

  const { data }  = event
  Logger.sessao.iniciar(data.idusuario, data.osUser)
});

ReactDOM.render(
  <AppContainer />, document.getElementById('root')
);
registerServiceWorker();
