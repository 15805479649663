import React from "react"

const Modal = ({ idModal, titulo, footer, children, foto }) => {
    return (
        <div class="modal" id={idModal} aria-hidden="true">
            <div class={foto ? "modal-dialog-foto modal-dialog" : "modal-dialog"}>
                {
                    titulo && 
                <div class="modal-header">
                    <h2>{titulo}</h2>
                    <a href="#" class="btn-close" aria-hidden="true">×</a>
                </div>
                }
                <div class="modal-body">
                    {children}
                </div>
                {
                    footer &&
                    <div class="modal-footer">
                        {
                            footer?.()
                        }
                    </div>
                }
                
            </div>
        </div> 
    )
}

export default Modal