import Swal from 'sweetalert2'

const NotificationType = {
    Success: 'success',
    Error: 'error',
    Warn: 'warn',
    Info: 'info',
    Loading: 'loading'
}

const Notifications = [
    NotificationType.Success = { icon: 'success', title: 'Ok!' },
    NotificationType.Error = { icon: 'error', title: 'Ops!' },
    NotificationType.Warn = { icon: 'warning', title: 'Atenção!' },
    NotificationType.Info = { icon: 'info', title: 'Atenção!' },
    NotificationType.Loading = { icon: 'info', title: 'Aguarde!' },
]

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const ShowNotification = (notificationType, text) => Toast.fire({ ...Notifications[notificationType], text }) 

window.alert = (message) => ShowNotification(NotificationType.Info, message)

export { ShowNotification, NotificationType }
