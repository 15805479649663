import React, { useRef, useEffect, useState } from 'react'

export const useDebounce = (callback, delay) => {
  const latestCallback = useRef()
  const [callCount, setCallCount] = useState(0)

  const fire = async () => {
    try {
      await latestCallback.current()
    } catch (e) {
    } finally {
      setCallCount(0)
    }
  }
  useEffect(() => {
    latestCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (callCount > 0) {
      const handle = setTimeout(fire, delay)
      return () => {
        clearTimeout(handle)
      }
    }
  }, [callCount, delay])

  return () => setCallCount((callCount) => callCount + 1)
}
