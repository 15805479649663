import React, { useState, useEffect }  from "react"
import { Lobby, Switch, VideoRoomComponent } from '@components';
import { useSearchParameters } from '@hooks'

const AppContainer = () => {
    const [screenToShow, setScreenToShow] = useState('naoencontrado');
    const [userType, setUserType] = useState('cliente')
    const [sessionId, setSessionId] = useState();
    const searchParameters = useSearchParameters();

    useEffect(() => {
        const { session } = searchParameters
        setSessionId(session);
        setScreenToShow('lobby');
    }, [searchParameters]);

    return (
        <Switch value={screenToShow}>
            <Lobby value='lobby' setScreenToShow={setScreenToShow} setSessionId={setSessionId} setUserType={setUserType} />
            <VideoRoomComponent value='videoconferencia' sessionId={sessionId} userType={userType} ></VideoRoomComponent>
            <div value={'naoencontrado'}>Não encontrado</div>
        </Switch>
    )
}

export default AppContainer