import React, { Component } from 'react';

import { OpenVidu } from 'openvidu-browser';

import Input from '@material-ui/core/Input';
import {
    AcButton,
    ChatComponent,
    DialogExtensionComponent,
    Modal,
    StreamComponent,
    ToolbarComponent
} from '@components'
import { Logger } from '@logging';
import {
    enviarSenhaEmissao,
    entrarVideoconferenciaAgr,
    entrarVideoconferenciaCliente,
    iniciarGravacaoVideoconferencia,
    finalizarGravacaoVideoconferencia,
    enviarCodigoOtp,
    validarCodigoOtp, assumirEdicaoSolicitacao
} from '@services'

import OpenViduLayout from '../../layout/openvidu-layout';
import UserModel from '../../models/user-model';

import './styles.css';
import { NotificationType, ShowNotification } from '../../utils/notification';


var localUser = new UserModel();
class VideoRoomComponent extends Component {
    constructor(props) {
        super(props);
        this.hasBeenUpdated = false;
        this.layout = new OpenViduLayout();
        let sessionName = this.props.sessionId ? this.props.sessionId : 'SessionTeste';
        let userName = this.props.user ? this.props.user : 'OpenVidu_User' + Math.floor(Math.random() * 100);
        this.remotes = [];
        this.localUserAccessAllowed = false;
        this.state = {
            mySessionId: sessionName,
            myUserName: userName,
            session: undefined,
            localUser: undefined,
            subscribers: [],
            chatDisplay: 'none',
            imageCaptured: {
                href: '',
                width: 0,
                height: 0,
            },
            remoteRecorder: undefined,
            recordingId: '',
            loading: false,
            otp: ''
        };

        this.joinSession = this.joinSession.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
        this.updateLayout = this.updateLayout.bind(this);
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.nicknameChanged = this.nicknameChanged.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.closeDialogExtension = this.closeDialogExtension.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
        this.checkNotification = this.checkNotification.bind(this);
        this.checkSize = this.checkSize.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.abrirModalValidarOtp = this.abrirModalValidarOtp.bind(this);
        this.envioCodigoOtp = this.envioCodigoOtp.bind(this);
        this.validaCodigoOtp = this.validaCodigoOtp.bind(this);
        this.handleChangeOtp = this.handleChangeOtp.bind(this);
    }

    componentDidMount() {
        const openViduLayoutOptions = {
            maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
            minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
            fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
            bigClass: 'OV_big', // The class to add to elements that should be sized bigger
            bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
            bigFixedRatio: false, // fixedRatio for the big ones
            bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
            bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
            bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
            animate: true, // Whether you want to animate the transitions
        };

        this.layout.initLayoutContainer(document.getElementById('layout'), openViduLayoutOptions);
        window.addEventListener('beforeunload', this.onbeforeunload);
        window.addEventListener('resize', this.updateLayout);
        window.addEventListener('resize', this.checkSize);
        this.joinSession();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
        window.removeEventListener('resize', this.updateLayout);
        window.removeEventListener('resize', this.checkSize);
        this.leaveSession();
    }

    onbeforeunload(event) {
        this.leaveSession();
    }

    joinSession() {
        this.OV = new OpenVidu();

        this.setState(
            {
                session: this.OV.initSession(),
            },
            () => {
                this.subscribeToStreamCreated();
                this.connectToSession();
            },
        );
    }

    async connectToSession() {
        if (this.props.token !== undefined) {
            this.connect(this.props.token);
        } else {
            try {
                const response = await this.getToken()
                this.connect(response.data.token);
            } catch (erro) {
                Logger.enviar(erro)
            }
        }
    }

    connect(token) {
        this.state.session
            .connect(
                token,
                { clientData: this.state.myUserName },
            )
            .then(() => {
                this.connectWebCam();
            })
            .catch((erro) => {
              if (this.props.error) {
                this.props.error({
                  error: erro.error,
                  message: erro.message,
                  code: erro.code,
                  status: erro.status,
                });
              }
              ShowNotification(
                NotificationType.Error,
                "Houve um erro ao entrar na sala de videoconferência. Atualize a página e se não resolver, contate o suporte."
              );
              Logger.enviar(erro)
            });
    }

    connectWebCam() {
        let publisher = this.OV.initPublisher(undefined, {
            audioSource: undefined,
            videoSource: undefined,
            publishAudio: localUser.isAudioActive(),
            publishVideo: localUser.isVideoActive(),
            resolution: '640x360',
            frameRate: 25,
            insertMode: 'APPEND',
            filter: {
                type: "GStreamerFilter",
                options: {
                    command: 'clockoverlay xpad=0 ypad=25 halignment=center font-desc="Sans, 8" time-format="%d/%m/%Y %H:%M:%S %Z" shaded-background=true'
                }
            }
        });

        if (this.state.session.capabilities.publish) {
            publisher.on('accessAllowed' , () => {
                this.state.session.publish(publisher).then(() => {
                    this.updateSubscribers();
                    this.localUserAccessAllowed = true;
                    if (this.props.joinSession) {
                        this.props.joinSession();
                    }
                });
            });

        }
        localUser.setNickname(this.state.myUserName);
        localUser.setConnectionId(this.state.session.connection.connectionId);
        localUser.setScreenShareActive(false);
        localUser.setStreamManager(publisher);
        this.subscribeToUserChanged();
        this.subscribeToStreamDestroyed();
        this.sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive() });

        this.setState({ localUser: localUser }, () => {
            this.state.localUser.getStreamManager().on('streamPlaying', (e) => {
                this.updateLayout();
                publisher.videos[0].video.parentElement.classList.remove('custom-class');
            });
        });
    }

    updateSubscribers() {
        var subscribers = this.remotes;
        this.setState(
            {
                subscribers: subscribers,
            },
            () => {
                if (this.state.localUser) {
                    this.sendSignalUserChanged({
                        isAudioActive: this.state.localUser.isAudioActive(),
                        isVideoActive: this.state.localUser.isVideoActive(),
                        nickname: this.state.localUser.getNickname(),
                        isScreenShareActive: this.state.localUser.isScreenShareActive(),
                    });
                }
                this.updateLayout();
            },
        );
    }

    leaveSession() {
        const mySession = this.state.session;

        if (mySession) {
            mySession.disconnect();
        }

        // Empty all properties...
        this.OV = null;
        this.setState({
            session: undefined,
            subscribers: [],
            mySessionId: 'SessionA',
            myUserName: 'OpenVidu_User' + Math.floor(Math.random() * 100),
            localUser: undefined,
        });
        if (this.props.leaveSession) {
            this.props.leaveSession();
        }
    }
    camStatusChanged() {
        localUser.setVideoActive(!localUser.isVideoActive());
        localUser.getStreamManager().publishVideo(localUser.isVideoActive());
        this.sendSignalUserChanged({ isVideoActive: localUser.isVideoActive() });
        this.setState({ localUser: localUser });
    }

    micStatusChanged() {
        localUser.setAudioActive(!localUser.isAudioActive());
        localUser.getStreamManager().publishAudio(localUser.isAudioActive());
        this.sendSignalUserChanged({ isAudioActive: localUser.isAudioActive() });
        this.setState({ localUser: localUser });
    }

    nicknameChanged(nickname) {
        let localUser = this.state.localUser;
        localUser.setNickname(nickname);
        this.setState({ localUser: localUser });
        this.sendSignalUserChanged({ nickname: this.state.localUser.getNickname() });
    }

    deleteSubscriber(stream) {
        const remoteUsers = this.state.subscribers;
        const userStream = remoteUsers.filter((user) => user.getStreamManager().stream === stream)[0];
        let index = remoteUsers.indexOf(userStream, 0);
        if (index > -1) {
            remoteUsers.splice(index, 1);
            this.setState({
                subscribers: remoteUsers,
            });
        }
    }

    subscribeToStreamCreated() {
        this.state.session.on('streamCreated', (event) => {
            const subscriber = this.state.session.subscribe(event.stream, undefined);
            // var subscribers = this.state.subscribers;
            subscriber.on('streamPlaying', (e) => {
                this.checkSomeoneShareScreen();
                subscriber.videos[0].video.parentElement.classList.remove('custom-class');
            });
            const newUser = new UserModel();
            newUser.setStreamManager(subscriber);
            newUser.setConnectionId(event.stream.connection.connectionId);
            newUser.setType('remote');
            const nickname = event.stream.connection.data.split('%')[0];
            newUser.setNickname(JSON.parse(nickname).clientData);
            this.remotes.push(newUser);
            if(this.localUserAccessAllowed) {
                this.updateSubscribers();
            }
        });
    }

    subscribeToStreamDestroyed() {
        // On every Stream destroyed...
        this.state.session.on('streamDestroyed', (event) => {
            // Remove the stream from 'subscribers' array
            this.deleteSubscriber(event.stream);
            setTimeout(() => {
                this.checkSomeoneShareScreen();
            }, 20);
            event.preventDefault();
            this.updateLayout();
        });
    }

    subscribeToUserChanged() {
        this.state.session.on('signal:userChanged', (event) => {
            let remoteUsers = this.state.subscribers;
            remoteUsers.forEach((user) => {
                if (user.getConnectionId() === event.from.connectionId) {
                    const data = JSON.parse(event.data);
                    console.log('EVENTO REMOTE: ', event.data);
                    if (data.isAudioActive !== undefined) {
                        user.setAudioActive(data.isAudioActive);
                    }
                    if (data.isVideoActive !== undefined) {
                        user.setVideoActive(data.isVideoActive);
                    }
                    if (data.nickname !== undefined) {
                        user.setNickname(data.nickname);
                    }
                    if (data.isScreenShareActive !== undefined) {
                        user.setScreenShareActive(data.isScreenShareActive);
                    }
                }
            });
            this.setState(
                {
                    subscribers: remoteUsers,
                },
                () => this.checkSomeoneShareScreen(),
            );
        });
    }

    updateLayout() {
        setTimeout(() => {
            this.layout.updateLayout();
        }, 20);
    }

    sendSignalUserChanged(data) {
        const signalOptions = {
            data: JSON.stringify(data),
            type: 'userChanged',
        };
        this.state.session.signal(signalOptions);
    }

    toggleFullscreen() {
        const document = window.document;
        const fs = document.getElementById('container');
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            if (fs.requestFullscreen) {
                fs.requestFullscreen();
            } else if (fs.msRequestFullscreen) {
                fs.msRequestFullscreen();
            } else if (fs.mozRequestFullScreen) {
                fs.mozRequestFullScreen();
            } else if (fs.webkitRequestFullscreen) {
                fs.webkitRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    screenShare() {
        const videoSource = navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';
        const publisher = this.OV.initPublisher(
            undefined,
            {
                videoSource: videoSource,
                publishAudio: localUser.isAudioActive(),
                publishVideo: localUser.isVideoActive(),
                mirror: false,
            },
            (error) => {
                if (error && error.name === 'SCREEN_EXTENSION_NOT_INSTALLED') {
                    this.setState({ showExtensionDialog: true });
                } else if (error && error.name === 'SCREEN_SHARING_NOT_SUPPORTED') {
                    alert('Your browser does not support screen sharing');
                } else if (error && error.name === 'SCREEN_EXTENSION_DISABLED') {
                    alert('You need to enable screen sharing extension');
                } else if (error && error.name === 'SCREEN_CAPTURE_DENIED') {
                    alert('You need to choose a window or application to share');
                }
            },
        );

        publisher.once('accessAllowed', () => {
            this.state.session.unpublish(localUser.getStreamManager());
            localUser.setStreamManager(publisher);
            this.state.session.publish(localUser.getStreamManager()).then(() => {
                localUser.setScreenShareActive(true);
                this.setState({ localUser: localUser }, () => {
                    this.sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive() });
                });
            });
        });
        publisher.on('streamPlaying', () => {
            this.updateLayout();
            publisher.videos[0].video.parentElement.classList.remove('custom-class');
        });
    }

    closeDialogExtension() {
        this.setState({ showExtensionDialog: false });
    }

    stopScreenShare() {
        this.state.session.unpublish(localUser.getStreamManager());
        this.connectWebCam();
    }

    checkSomeoneShareScreen() {
        let isScreenShared;
        // return true if at least one passes the test
        isScreenShared = this.state.subscribers.some((user) => user.isScreenShareActive()) || localUser.isScreenShareActive();
        const openviduLayoutOptions = {
            maxRatio: 3 / 2,
            minRatio: 9 / 16,
            fixedRatio: isScreenShared,
            bigClass: 'OV_big',
            bigPercentage: 0.8,
            bigFixedRatio: false,
            bigMaxRatio: 3 / 2,
            bigMinRatio: 9 / 16,
            bigFirst: true,
            animate: true,
        };
        this.layout.setLayoutOptions(openviduLayoutOptions);
        this.updateLayout();
    }

    toggleChat(property) {
        let display = property;

        if (display === undefined) {
            display = this.state.chatDisplay === 'none' ? 'block' : 'none';
        }
        if (display === 'block') {
            this.setState({ chatDisplay: display, messageReceived: false });
        } else {
            console.log('chat', display);
            this.setState({ chatDisplay: display });
        }
        this.updateLayout();
    }

    checkNotification(event) {
        this.setState({
            messageReceived: this.state.chatDisplay === 'none',
        });
    }
    checkSize() {
        if (document.getElementById('layout').offsetWidth <= 700 && !this.hasBeenUpdated) {
            this.toggleChat('none');
            this.hasBeenUpdated = true;
        }
        if (document.getElementById('layout').offsetWidth > 700 && this.hasBeenUpdated) {
            this.hasBeenUpdated = false;
        }
    }

    handleChangeOtp({ target }){
        const { value } = target

        this.setState({
            otp: value
        })
    }

    tirarFoto(remoteUser){
        if(remoteUser){
            const remoteUsers = document.getElementsByTagName('video')[1]

            const width = remoteUsers.videoWidth;
            const height = remoteUsers.videoHeight;

            const avatar = document.createElement('canvas');

            avatar.className = 'user-img';
            avatar.width = width;
            avatar.height = height;

            const avatarContext = avatar.getContext('2d');
            avatarContext.drawImage(remoteUsers, 0, 0, width, height);

            const image = {
                href: avatar.toDataURL('image/jpeg'),
                width,
                height,
            }

            this.setState({ imageCaptured: image });
            this.abrirModalConfirmacaoFoto();
        }
    }

    abrirModalValidarOtp(){
        this.abrirModal('modal-otp')
    }

    abrirModalConfirmacaoFoto(){
        this.abrirModal('modal-open')
    }

    abrirModalGravacaoConcluida(){
        this.abrirModal('modal-link')
    }

    abrirModal(nomeModal){
        setTimeout(() => {
            document.getElementById(nomeModal).click();
        }, 500)
    }

    async enviarSenhaEmissao(idSolicitacao){
        try{
            await enviarSenhaEmissao(idSolicitacao)
            ShowNotification(NotificationType.Success, 'Senha gerada e enviada por e-mail e SMS para o cliente, confirme o recebimento!')
        }catch(erro){
            Logger.enviar(erro)
        }
    }

    render() {
        const mySessionId = this.state.mySessionId;
        const localUser = this.state.localUser;
        const otp = this.state.otp
        var chatDisplay = { display: this.state.chatDisplay };
        const remoteUser = this.state.subscribers;

        return (
            <>
            <Modal idModal="modal-otp-1" titulo="Validação OTP">
                <div style={{ width: '100%' }}>
                    <Input placeholder="Código OTP" onChange={this.handleChangeOtp} inputProps={{ maxLength: 4 }}
 />
                </div>
                <div style={{ marginTop: 10 }}>
                    <AcButton disabled={otp?.length < 4} onClick={() => this.validaCodigoOtp(mySessionId, otp)}>
                        Validar código OTP
                    </AcButton>
                </div>
            </Modal>
            {
                this.state.recordingId && 
                <Modal idModal={"modal-link-1"} titulo={"Sucesso!"}>
                    <span>Gravação concluida com sucesso!</span>
                </Modal>
            }
            {
                this.state.imageCaptured.href &&
                <Modal foto idModal={"modal-one"} titulo={'A foto ficou boa?'}
                footer={() => {
                    return (
                        <>
                            <a style={{ background: "#B22222", marginRight: "5px" }} href="#" class="btn">NÃO</a>
                            <a style={{ background: "#228B22" }} onClick={() => { 
                                var a = document.createElement("a");
                                a.href = this.state.imageCaptured.href;
                                a.download = 'foto.jpg';
                                a.click();
                                this.setState({ showMoldura: false })
                            }} href="#" class="btn">SIM</a>
                            <div style={{ height: 100 }}></div>
                        </>
                    )
                }}>
                    <img width={this.state.imageCaptured.width} height={this.state.imageCaptured.height} src={this.state.imageCaptured.href} />
                </Modal>
            }
            <a id="modal-otp" href="#modal-otp-1" class="btn btn-big"></a>
            <a id="modal-open" href="#modal-one" class="btn btn-big"></a>
            <a id="modal-link" href="#modal-link-1" class="btn btn-big"></a>
            <div className="container" id="container">
                <ToolbarComponent
                    sessionId={mySessionId}
                    user={localUser}
                    tipoUsuario={this.props.userType}
                    showNotification={this.state.messageReceived}
                    camStatusChanged={this.camStatusChanged}
                    micStatusChanged={this.micStatusChanged}
                    screenShare={this.screenShare}
                    stopScreenShare={this.stopScreenShare}
                    toggleFullscreen={this.toggleFullscreen}
                    leaveSession={this.leaveSession}
                    toggleChat={this.toggleChat}
                    tirarFoto={() => this.tirarFoto(remoteUser)}
                    iniciarGravar={() => this.startRecording(mySessionId)}
                    pararGravar={() => this.stopRecording(mySessionId, this.state.recordingId)}
                    loading={this.state.loading}
                    validarCodigoOtp={this.abrirModalValidarOtp}
                    enviarCodigotOtp={() => this.envioCodigoOtp(mySessionId)}
                    enviarSenhaEmissao={() => this.enviarSenhaEmissao(mySessionId)}
                />
                
                <DialogExtensionComponent showDialog={this.state.showExtensionDialog} cancelClicked={this.closeDialogExtension} />

                <div id="layout" className="bounds">
                    {localUser !== undefined && localUser.getStreamManager() !== undefined && (
                        <div className="OT_root OT_publisher custom-class" id="localUser">
                            <StreamComponent user={localUser} handleNickname={this.nicknameChanged} />
                        </div>
                    )}
                    {remoteUser.map((sub, i) => (
                        <div key={i} className="OT_root OT_publisher custom-class" id="remoteUsers">
                            <StreamComponent user={sub} streamId={sub.streamManager.stream.streamId} />
                        </div>
                    ))}
                    {localUser !== undefined && localUser.getStreamManager() !== undefined && (
                        <div className="OT_root OT_publisher custom-class" style={chatDisplay}>
                            <ChatComponent
                                user={localUser}
                                chatDisplay={this.state.chatDisplay}
                                close={this.toggleChat}
                                messageReceived={this.checkNotification}
                            />
                        </div>
                    )}
                </div>
            </div>
            </>
        );
    }

    async getToken() {
        const data = { idSolicitacao: this.state.mySessionId }
        try{
            await this.assumirEdicao(this.state.mySessionId)
            const response = await this.entrarVideoconferencia(data)
            return response
        } catch (erro) {
          Logger.enviar(erro)
        }
    }

    async entrarVideoconferencia(data){
        return this.props.userType === 'agr' ?
           await entrarVideoconferenciaAgr(data)
            :
            await entrarVideoconferenciaCliente(data)
    }

    async assumirEdicao(data){
        return this.props.userType === 'agr' &&
       await assumirEdicaoSolicitacao(data) 
    }
    async startRecording(sessionId){
        const data = { idSolicitacao: sessionId }
        try{
            this.setState({
                loading: true
            })
            const response = await iniciarGravacaoVideoconferencia(data)
            this.setState({
                recordingId: response.data.idGravacao
            })
            return response
        } catch (erro) {
          Logger.enviar(erro)
          throw erro
        }
        finally{
            this.setState({
                loading: false
            })
        }
    }

    async stopRecording(sessionId, recordingId){
        const data = { idSolicitacao: sessionId, idGravacao: recordingId }
        try{
            this.setState({
                loading: true
            })
            const response = await finalizarGravacaoVideoconferencia(data)
            
           this.abrirModalGravacaoConcluida()

            return response
        } catch (erro) {
          Logger.enviar(erro)
          throw erro
        }
        finally{
            this.setState({
                loading: false
            })
        }
    }

    async envioCodigoOtp(sessionId){
        try{
            this.setState({
                loading: true
            })
            await enviarCodigoOtp(sessionId)
            ShowNotification(NotificationType.Success, 'Código OTP enviado com sucesso!')
        } catch (erro) {
          Logger.enviar(erro)
          throw erro
        }
        finally{
            this.setState({
                loading: false
            })
        }
    }

    async validaCodigoOtp(sessionId, otp){
        try{
            this.setState({
                loading: true
            })
            await validarCodigoOtp(sessionId, otp)
            ShowNotification(NotificationType.Success, 'Código OTP validado com sucesso!')
        } catch (erro) {
          Logger.enviar(erro)
        }
        finally{
            this.setState({
                loading: false
            })
        }
    }
}
export default VideoRoomComponent;
