import React, { useState, useEffect, useCallback } from 'react'
import { useDebounce } from '@hooks'

const AcButton = ({ onClick, disabled, timeWait, ...props }) => {
  const [isWaitingAsync, setIsWaitingAsync] = useState(false)
  const [wait, setWait] = useState(false)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    if(seconds > 0){
      setTimeout(() => {
        setSeconds(seconds - 1)
      }, 1000)
    }
  }, [seconds])

  const handleWait = useCallback(() => {
    setWait(true)
    setSeconds(timeWait / 1000)
    setTimeout(() => {
      setWait(false)
    }, [timeWait])
  }, [timeWait])

  const handleExecuteClick = async () => {
    try {
      setIsWaitingAsync(true)
      await onClick()
      if(timeWait) handleWait()
    } catch (e) {
    } finally {
      setIsWaitingAsync(false)
    }
  }

  const handleClick = useDebounce(handleExecuteClick, 500)

  return (
    <>
      <button
        {...props}
        onClick={handleClick}
        disabled={disabled || isWaitingAsync || wait || seconds > 0}
        className="button-primary"
      >
        {props.children}
        {seconds ? 
        <p className='textoAguardeBotao'>Aguarde {seconds} segundos</p> 
        : 
        <></>}
      </button>
    </>
  )
}

export default AcButton