const env = {
  ENV: 'PROD',
  SENTRY: {
    habilitado: true,
    ambiente: 'production' 
  },
  BASE_URL: 'https://acfaz-api.acdigital.com.br',
  TOKEN: {
    clientId: 'pYBu0cADnQ9UxWr7x7f8EkSt0iq3FxQI',
    clientSecret: 'CK84XS2DVVFKtdsnbHakcVspwU7jbTe4Xdx2GnCrrtZwZ9SHyUzBs5umXx8bXgZYcW5x8NuN2d9tTaNAh6Cz8GH2SnchZUd7',
    username: 'videoconferencia',
    password: '0paUoAy4S8gpIcPIajEY2g0nW3R0YNmn',
    grant_type_basic: 'password',
    grant_type_refresh: 'refresh'
  },
}
export default env