import React, { useCallback, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import Tooltip from '@material-ui/core/Tooltip';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import IconButton from '@material-ui/core/IconButton';

import { AcButton } from '@components'
import { Logger } from "@logging";
import { NotificationType, ShowNotification } from '../../utils/notification';

import './styles.css';


const logo = require('../../assets/images/logoac.png');

const ToolbarComponent = ({
    micStatusChanged,
    camStatusChanged,
    toggleFullscreen,
    leaveSession,
    toggleChat,
    tirarFoto,
    pararGravar,
    iniciarGravar,
    user: localUser,
    tipoUsuario,
    showNotification,
    loading,
    validarCodigoOtp,
    enviarCodigotOtp,
    enviarSenhaEmissao
}) => {
    const [fullscreen, setFullscreen] = useState(false)
    const [recording, setRecording] = useState(false)
    const [habilitaValidar, setHabilitaValidar] = useState(false)

    const handleIniciarGravacao = useCallback(async () => {
        try{
            await iniciarGravar()
            setRecording(true)
        } catch (erro) {
          Logger.enviar(erro);
        }
        
    }, [iniciarGravar])

    const handlePararGravacao = useCallback(async () => {
        try{
            await pararGravar()
            setRecording(false)
        } catch (erro) {
          Logger.enviar(erro);
        }
        
    }, [pararGravar])

    const handleToggleFullscreen = useCallback(() => {
        toggleFullscreen();
        setFullscreen(!fullscreen)
    }, [toggleFullscreen, fullscreen])

    const handleValidarOtp = useCallback(() => {
        validarCodigoOtp()
    }, [validarCodigoOtp])

    const handleEnviarCodigoOtp = useCallback(async () => {
        try{
            await enviarCodigotOtp()
            setHabilitaValidar(true)
        } catch (erro) {
          Logger.enviar(erro);
        }
    }, [enviarCodigotOtp])

    const handleFecharVideoconferencia = () => {
        leaveSession()
        ShowNotification(NotificationType.Warn, 'Sua videoconferência encerrou, você já pode fechar esta janela.')
    }

    const handleReiniciarVideoconferencia = () => {
        window.location.reload(true)
    }
    
    const handleGerarEnviarSenhaDeEmissao = useCallback(() => {
        enviarSenhaEmissao()
    }, [enviarSenhaEmissao])

    return (
        <AppBar className="toolbar" id="header">
                <Toolbar className="toolbar">
                    <div id="logo">
                        <img alt="AC Digital Logo" src={logo} />
                    </div>
                    {
                        tipoUsuario === "agr" ?
                        <AcButton disabled={loading || recording} onClick={handleGerarEnviarSenhaDeEmissao} timeWait={30000} >
                            Gerar e enviar senha de emissão
                        </AcButton>
                        :
                        <></>
                    }
                    <div className="toolbar-buttons">
                    {
                        tipoUsuario === "agr" ?
                        <>
                            <AcButton disabled={loading} onClick={handleEnviarCodigoOtp}>
                                Enviar código OTP
                            </AcButton>
                            <AcButton disabled={!habilitaValidar || loading} onClick={handleValidarOtp}>
                                Validar código OTP
                            </AcButton>
                            <AcButton disabled={loading} onClick={tirarFoto}>
                                Tirar Foto
                            </AcButton>
                            <AcButton disabled={loading || !habilitaValidar} onClick={recording ? handlePararGravacao : handleIniciarGravacao}>
                                {
                                    recording ?
                                    "Parar Gravação" 
                                    :
                                    "Iniciar Gravação"
                                }
                            </AcButton>
                        </>
                        :
                        <>
                            <AcButton onClick={handleFecharVideoconferencia}>
                                Fechar<span className="hidden-small"> videoconferência</span>
                            </AcButton>
                            <AcButton onClick={handleReiniciarVideoconferencia}>
                                Reiniciar<span className="hidden-small"> videoconferência</span>
                            </AcButton>
                        </>
                    }
                    
                        <IconButton color="inherit" className="navButton" id="navMicButton" onClick={micStatusChanged}>
                            {localUser !== undefined && localUser.isAudioActive() ? <Mic /> : <MicOff color="secondary" />}
                        </IconButton>

                        <IconButton color="inherit" className="navButton" id="navCamButton" onClick={camStatusChanged}>
                            {localUser !== undefined && localUser.isVideoActive() ? (
                                <Videocam />
                            ) : (
                                <VideocamOff color="secondary" />
                            )}
                        </IconButton>
                        <IconButton color="inherit" className="navButton" onClick={handleToggleFullscreen}>
                            {localUser !== undefined && fullscreen ? <FullscreenExit /> : <Fullscreen />}
                        </IconButton>
                    </div>

                    <div className="secondary-buttons">
                        <IconButton color="inherit" onClick={toggleChat} id="navChatButton">
                            {showNotification && <div id="point" className="" />}
                            <Tooltip title="Chat">
                                <QuestionAnswer />
                            </Tooltip>
                        </IconButton>
                    </div>                    
                </Toolbar>
            </AppBar>
    )
}

export default ToolbarComponent